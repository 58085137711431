import { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { getStorage, ref, getDownloadURL, getBlob } from "firebase/storage";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { auth, logout } from "../firebase";
import { ROUTES } from "../utils/constants";

export default function CheckoutForm() {
  const { HOME } = ROUTES;
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const [email, setEmail] = useState<any>(null);
  const [message, setMessage] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [downloadFile, setDownloadFile] = useState(false);
  const storage = getStorage();

  useEffect(() => {
    if (!stripe) return;

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) return;

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent?.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          setDownloadFile(true);
          break;

        case "processing":
          setMessage("Your payment is processing.");
          break;

        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          setDownloadFile(false);
          break;

        default:
          setMessage("Something went wrong.");
          setDownloadFile(false);
          break;
      }
    });
  }, [stripe]);

  useEffect(() => {
    if (downloadFile) {
      downloadFileFunc();
      setTimeout(() => navigate(HOME), 3000);
    }
  }, [downloadFile]);

  useEffect(() => {
    user && setEmail(user.email);
  }, [user]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url:
          "http://localhost:3000/checkout" ||
          "https://diana-molinari.web.app/checkout",
        receipt_email: email,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occured.");
    }
    setIsLoading(false);
  };

  function downloadFileFunc() {
    getBlob(ref(storage, `${process.env.REACT_APP_FIREBASE_BOOK_URL}`))
      .then((blob) => {
        // const file = new File([blob], "o_despertar_da_bruxa");
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.target = "_blank";
        link.href = url;
        link.download = "o_despertar_da_bruxa";
        link.dispatchEvent(new MouseEvent("click"));
      })
      .catch((error) => {
        switch (error.code) {
          case "storage/object-not-found":
            // File doesn't exist
            break;
          case "storage/unauthorized":
            // User doesn't have permission to access the object
            break;
          case "storage/canceled":
            // User canceled the upload
            break;
          // ...
          case "storage/unknown":
            // Unknown error occurred, inspect the server response
            break;
        }
      });
  }

  return (
    <section>
      <form id='payment-form' onSubmit={handleSubmit}>
        <section className='content-lg-pd'>
          <PaymentElement id='payment-element' />
        </section>
        <button disabled={isLoading || !stripe || !elements} id='submit'>
          <span id='button-text'>
            {isLoading
              ? // <div className='spinner' id='spinner'></div>
                "Carregando"
              : "Pagar agora"}
          </span>
        </button>
      </form>
      {message && <p id='payment-message'>{message}</p>}
    </section>
  );
}
