import { useState, useEffect } from "react";
import "./styles/main.sass";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Blog from "./pages/Blog";
import SinglePost from "./pages/Post";
import Contact from "./pages/Contact";
import Error from "./pages/Error";
import Checkout from "./pages/Checkout";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Reset from "./pages/Reset";
import { ROUTES } from "./utils/constants";
import AppContext from "./context/Context";

function App(): JSX.Element | null {
  const {
    HOME,
    ABOUT,
    BLOG,
    BLOG_ID,
    CONTACT,
    NOT_FOUND,
    CHECKOUT,
    LOGIN,
    REGISTER,
    RESET,
  } = ROUTES;
  return (
    <AppContext>
      <BrowserRouter>
        <Routes>
          <Route path={HOME} element={<Home />} />
          <Route path={ABOUT} element={<About />} />
          <Route path={BLOG} element={<Blog />} />
          <Route path={BLOG_ID} element={<SinglePost />} />
          <Route path={CONTACT} element={<Contact />} />
          <Route path={NOT_FOUND} element={<Error />} />
          <Route path={CHECKOUT} element={<Checkout />} />
          <Route path={LOGIN} element={<Login />} />
          <Route path={REGISTER} element={<Register />} />
          <Route path={RESET} element={<Reset />} />
        </Routes>
      </BrowserRouter>
    </AppContext>
  );
}

export default App;
