import { FC } from "react";
import { Link } from "react-router-dom";
import { ROUTES, MOBILE_WIDTH } from "../utils/constants";
import { MenuProps, MenuItems } from "../utils/types";
const { HOME, ABOUT, BLOG, CONTACT, LOGIN, REGISTER } = ROUTES;
const menuItems: MenuItems[] = [
  {
    url: HOME,
    name: "Home",
  },
  {
    url: ABOUT,
    name: "Sobre",
  },
  {
    url: BLOG,
    name: "Blog",
  },
  {
    url: CONTACT,
    name: "Contact",
  },
];
const Menu: FC<MenuProps> = ({ className }) => {
  return (
    <nav className='main-nav'>
      <ul className={className}>
        {menuItems &&
          menuItems.map((item, index) => (
            <li key={index}>
              <Link to={item.url}>{item.name}</Link>
            </li>
          ))}
      </ul>
    </nav>
  );
};

export default Menu;
