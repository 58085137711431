import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import Layout from "../interfaces/Layout";
import useContent from "../hooks/useContent";
import { PAGE_TYPES, PAGES, SITE_URL, ROUTES } from "../utils/constants";
import Loading from "./Loading";
import { Link } from "react-router-dom";

const Home = (): JSX.Element => {
  const { PAGE } = PAGE_TYPES;
  const { HOME } = PAGES;
  const { CHECKOUT } = ROUTES;
  const [page, isLoading] = useContent(PAGE, HOME);

  if (isLoading) return <Loading />;
  const contentDescription = documentToReactComponents(page.content);

  return (
    <Layout background='movingBG'>
      <section className='container lg all-center-v flex-direction-column'>
        <h1 className='center'>{page.title}</h1>
        <div className='content-lg-pd'>
          <img
            src={page.logo.fields.file.url}
            className='book-cover'
            alt='logo'
          />
        </div>
        <div>{contentDescription}</div>
        <Link to={CHECKOUT}>
          <button>Comprar Agora</button>
        </Link>
      </section>
    </Layout>
  );
};

export default Home;
