import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import Layout from "../interfaces/Layout";
import useContent from "../hooks/useContent";
import { PAGE_TYPES, PAGES } from "../utils/constants";
import Loading from "./Loading";
import { FaFacebookF, FaWhatsapp, FaInstagram } from "react-icons/fa";
import { FiMail } from "react-icons/fi";

const Contact = (): JSX.Element => {
  const { PAGE } = PAGE_TYPES;
  const { CONTATO } = PAGES;
  const [page, isLoading] = useContent(PAGE, CONTATO);

  if (isLoading) return <Loading />;
  const contentDescription = documentToReactComponents(page.content);

  return (
    <Layout background='staticBGLight'>
      <section className='container lg all-center-v flex-direction-column'>
        <h1>{page.title}</h1>
        <p>{contentDescription}</p>
        <ul className='seamless icon-list'>
          <li>
            <a href='mailto:alcidiaanamolinari@gmail.com?subject=Contato através do site&body=Olá Diana tudo bem? Estou entrando em contato através do seu site'>
              <FiMail className='social-icons' size={25} />
              alcidiaanamolinari@gmail.com
            </a>
          </li>
          <li>
            <a
              href='https://wa.me/+393516133192?text=Olá%20Diana%20tudo%20bem?%20Estou%20entrando%20em%20contato%20através%20do%20seu%20site'
              target='_blank'
              rel='noreferrer'
            >
              <FaWhatsapp className='social-icons' size={25} />
              +39 351 613 3192
            </a>
          </li>
          <li>
            <a
              href='https://instagram.com/alcidiamolinari'
              target='_blank'
              rel='noreferrer'
            >
              <FaInstagram className='social-icons' size={25} />
              @alcidiamolinari
            </a>
          </li>
          <li>
            <a
              href='https://www.facebook.com/diana.mulinario'
              target='_blank'
              rel='noreferrer'
            >
              <FaFacebookF className='social-icons' size={25} />
              diana.mulinario
            </a>
          </li>
        </ul>
      </section>
    </Layout>
  );
};
export default Contact;
