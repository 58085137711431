import { useState, useEffect, createContext } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../firebase";
import { query, collection, getDocs, where } from "firebase/firestore";
import { TContext } from "../utils/types";

export const Context = createContext<TContext | null>(null);

const AppContext = ({ children }: any) => {
  const [user, loading, error] = useAuthState(auth);
  const [name, setName] = useState("");

  const fetchUserName = async () => {
    try {
      const q = query(collection(db, "users"), where("uid", "==", user?.uid));
      const doc = await getDocs(q);
      const data = doc.docs[0].data();
      setName(data.name);
    } catch (error) {
      console.error(error);
      alert("An error occured while fetching user data");
    }
  };

  useEffect(() => {
    if (loading) return;
    if (!user) return;
    fetchUserName();
  }, [user, loading]);

  const userContext: TContext = {
    name: name,
    email: user?.email ? user?.email : "",
  };
  return <Context.Provider value={userContext}>{children}</Context.Provider>;
};

export default AppContext;
