import { createContext, useState } from "react";

export const activeIndexContext = createContext<any>(null);

type Contextprops = {
  children: React.ReactNode | React.ReactNode[];
};

const ActiveIndexProvider = (props: Contextprops) => {
  const [activeIndex, setActiveIndex] = useState(0);
  return (
    <activeIndexContext.Provider value={[activeIndex, setActiveIndex]}>
      {props.children}
    </activeIndexContext.Provider>
  );
};

export default ActiveIndexProvider;
