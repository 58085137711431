import "../styles/main.sass";
import { useEffect, useRef, useContext } from "react";
import { useSwipeable } from "../Swipeable";
import useCarousel from "../hooks/useCarousel";
import { CarouselProps, CarouselItemProps } from "./types";
import {
  nextSlide,
  isAutoSlide,
  clickPrev,
  clickNext,
  checkBooleanProp,
} from "./helpers";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { CarouselDots } from "./components";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { activeIndexContext } from "../context/ActiveIndexProvider";

export const CarouselItem = (props: CarouselItemProps): JSX.Element | null => {
  const { children } = props;
  const [activeIndex] = useContext(activeIndexContext);

  return (
    <div
      className='carousel-el'
      style={{
        transform: `translate3d(${-activeIndex * 100}%, 0, 0)`,
      }}
    >
      {children}
    </div>
  );
};

export const CarouselConfig = (props: CarouselProps): JSX.Element | null => {
  const {
    data,
    children,
    arrows,
    seconds,
    autoOnDesktop,
    autoOnMobile,
    thumbsArrows,
    total,
    thumbsDirection,
    color,
    dotName,
  } = props;
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const { width } = useWindowDimensions();

  const autoSlideBoolean = isAutoSlide(
    width,
    checkBooleanProp(autoOnDesktop),
    checkBooleanProp(autoOnMobile)
  );

  const {
    setSelectedItem,
    selectedItem,
    activeIndex,
    setActiveIndex,
    autoSlide,
    setAutoSlide,
    autoSlideSeconds,
  } = useCarousel(data, autoSlideBoolean, seconds ? seconds : 2);

  useEffect(() => {
    setAutoSlide(() => autoSlideBoolean);
  }, [width]);

  const filtered = (arr: any) =>
    arr[activeIndex] && arr.find((item: any) => item === arr[activeIndex]);

  const states = {
    selectedItem,
    activeIndex,
    setActiveIndex,
    autoSlide,
    setAutoSlide,
    autoSlideSeconds,
  };

  function resetTimeout() {
    if (timeoutRef && timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  useEffect(() => {
    setSelectedItem(filtered(data));
  }, [activeIndex]);

  const timeToSlide = autoSlideSeconds ? autoSlideSeconds * 1000 : 5000;

  useEffect(() => {
    if (autoSlide) {
      resetTimeout();
      timeoutRef.current = setTimeout(
        () => nextSlide(data, states),
        timeToSlide
      );
    }

    return () => {
      resetTimeout();
    };
  }, [activeIndex, autoSlide]);

  const handlers = useSwipeable({
    onSwipedLeft: () => clickNext(states, data, states),
    onSwipedRight: () => clickPrev(states, data, states),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  return (
    <>
      <section className='carousel'>
        {arrows && (
          <div>
            <button
              style={{ color: color }}
              className='prev-btn'
              data-test='prev-btn'
              type='button'
              onClick={() => clickPrev(states, data, states)}
              aria-label='previous'
            >
              <BsChevronLeft size={30} />
            </button>
          </div>
        )}
        <section
          data-test='carousel-container'
          className={`carousel-container`}
          {...handlers}
        >
          {children}
        </section>
        {arrows && (
          <div>
            <button
              style={{ color: color }}
              className='next-btn'
              data-test='next-btn'
              type='button'
              onClick={() => clickNext(states, data, states)}
              aria-label='next'
            >
              <BsChevronRight size={30} />
            </button>
          </div>
        )}
      </section>
      <CarouselDots
        data={data}
        states={states}
        thumbsArrows={thumbsArrows}
        total={total}
        thumbsDirection={thumbsDirection}
        color={color}
        dotName={dotName}
      />
    </>
  );
};

export default CarouselConfig;
