import { useState, useContext } from "react";
import { activeIndexContext } from "../context/ActiveIndexProvider";

export const useCarousel = (
  data: Array<any>,
  autoSlideEffect: boolean,
  autoSlideInSeconds: number
) => {
  const [activeIndex, setActiveIndex] = useContext(activeIndexContext);
  const [selectedItem, setSelectedItem] = useState(data[activeIndex]);
  const [autoSlide, setAutoSlide] = useState(autoSlideEffect);
  const [autoSlideSeconds] = useState(autoSlideInSeconds);

  return {
    selectedItem,
    setSelectedItem,
    activeIndex,
    setActiveIndex,
    autoSlide,
    setAutoSlide,
    autoSlideSeconds,
  };
};

export default useCarousel;
