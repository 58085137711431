import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import Layout from "../interfaces/Layout";
import useContent from "../hooks/useContent";
import { PAGE_TYPES, PAGES } from "../utils/constants";
import Loading from "./Loading";

const About = (): JSX.Element => {
  const { PAGE } = PAGE_TYPES;
  const { SOBRE } = PAGES;
  const [page, isLoading] = useContent(PAGE, SOBRE);

  if (isLoading) return <Loading />;
  const contentDescription = documentToReactComponents(page.content);

  return (
    <Layout background='staticBGLight'>
      <section className='container lg all-center-v about'>
        <div>
          <img
            src={page.logo.fields.file.url}
            className='App-logo'
            alt='logo'
          />
        </div>
        <div className='text txt-left'>
          <h1>{page.title}</h1>
          <div>{contentDescription}</div>
        </div>
      </section>
    </Layout>
  );
};

export default About;
