import { useState, useEffect } from "react";
import Layout from "../interfaces/Layout";
import { ROUTES } from "../utils/constants";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "../components/CheckoutForm";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { auth, logout } from "../firebase";

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUB_TEST}`);

const Checkout = (): JSX.Element => {
  const { CREATE_PAYMENT, LOGIN } = ROUTES;
  const [clientSecret, setClientSecret] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();

  let resClone: any;

  useEffect(() => {
    fetch(`https://diana-molinari.web.app${CREATE_PAYMENT}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        // Origin: "https://diana-molinari.web.app/",
        // "Access-Control-Allow-Headers":
        //   "Origin, X-Requested-With, Content-Type, Accept",
      },
      body: JSON.stringify({ items: [{ id: "despertar-livro" }] }),
    })
      .then((res) => {
        resClone = res.clone(); // 2
        // console.log("res:", res.json());
        console.log(res);
        const contentType = res.headers.get("content-type");
        if (!contentType || !contentType.includes("application/json")) {
          // Or do something else with the result to get it into JSON
          throw new TypeError("Oops, we haven't got JSON!");
        }

        return res.json();
      })
      .then(
        (data) => {
          console.log("data:", data);
          setClientSecret(data.clientSecret);
        },
        (error) => {
          console.error("Error parsing JSON from response:", error, resClone); // 4
          resClone.text().then((bodyText: any) => {
            console.log(
              "Received the following instead of valid JSON:",
              bodyText
            ); // 6
          });
        }
      );
  }, []);

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate(LOGIN);
  }, [user, loading]);

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    ...appearance,
  };

  return (
    <Layout background='movingBG'>
      <section className='container lg all-center-v flex-direction-column'>
        <h3>Checkout</h3>
        {clientSecret && (
          <Elements options={options} stripe={stripePromise}>
            <CheckoutForm />
          </Elements>
        )}
      </section>
    </Layout>
  );
};
export default Checkout;
