import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth, logInWithEmailAndPassword, signInWithGoogle } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import Layout from "../interfaces/Layout";
import { ROUTES } from "../utils/constants";
import Loading from "./Loading";
import { signInWithEmailAndPassword } from "firebase/auth";
import e from "express";

const Login = (): JSX.Element => {
  const { REGISTER, RESET, CHECKOUT } = ROUTES;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  //   const [notFound, setNotFound] = useState(false)
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (loading) {
      return;
    }
    if (user) navigate(CHECKOUT);
    console.log("auth: ", auth);
  }, [user, loading, error, auth]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    logInWithEmailAndPassword(email, password);
  };

  return (
    <Layout background='staticBGDark'>
      <section className='container lg all-center-v flex-direction-column'>
        <h1>Login</h1>
        <article className='login'>
          <form className='login-container' onSubmit={handleSubmit}>
            <input
              type='email'
              className='login__textBox'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder='E-mail Address'
            />
            <input
              type='password'
              className='login__textBox'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder='Password'
            />
            <button type='submit'>Login</button>
            <button className='login-google' onClick={signInWithGoogle}>
              Login with Google
            </button>
            <div>
              <Link to={RESET}>Forgot Password</Link>
            </div>
            <div>
              Don't have an account? <Link to={REGISTER}>Register</Link> now
            </div>
          </form>
        </article>
      </section>
    </Layout>
  );
};
export default Login;
