import { useParams } from "react-router-dom";
import useSinglePost from "../hooks/useSinglePost";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { TImage } from "../utils/types";
import Layout from "../interfaces/Layout";
import SEO from "../components/SEO";
import Share from "../components/Share";
import { SITE_URL, KEYWORDS, TWITTER } from "../utils/constants";
import Loading from "./Loading";
import { CarouselItem } from "../components/Carousel/CarouselConfig";
import Carousel from "../components/Carousel";
import { useEffect } from "react";

export default function SinglePost(): JSX.Element {
  const { id } = useParams();

  const [post, isLoading] = useSinglePost(id);
  if (isLoading) return <Loading />;

  const { title, images, content, slug, cover, intro } = post;

  images && console.log("images: ", images);

  const contentDescription = documentToReactComponents(content);
  const twitter = "_fonsek";

  return (
    <Layout background='staticBGDark'>
      <SEO
        title={title}
        description={intro}
        url={`${SITE_URL}/blog/${slug}`}
        image={`http:${cover.fields.file.url}`}
        keywords={KEYWORDS}
        meta={[intro, title]}
      />
      <section className='container md'>
        <h2 className='txt-center'>{title}</h2>
        <Share
          socialConfig={{
            twitter,
            config: {
              url: `${SITE_URL}/blog/${slug}`,
              title,
            },
          }}
        />
        <div className='cover-img'>
          <img
            src={cover.fields.file.url}
            alt={cover.fields.title}
            title={cover.fields.title}
          />
        </div>
        <div>{contentDescription}</div>
        <div className='container-full'>
          <Carousel
            data={images}
            // arrows={false}
            autoOnDesktop={true}
            autoOnMobile={true}
            seconds={2}
            thumbsArrows={true}
            // thumbsDirection="LEFT"
            total={true}
            color='#FFD700'
            // dotName="SM-DOT"
          >
            {images &&
              images.map((img: TImage, index: number) => (
                <CarouselItem key={index}>
                  <img
                    className='featuredImage'
                    src={img.fields.file.url}
                    alt={img.fields.title}
                    title={img.fields.title}
                  />
                </CarouselItem>
              ))}
          </Carousel>
        </div>
        {/* {images &&
          images.map((img: TImage) => (
            <img
              className='featuredImage'
              src={img.fields.file.url}
              alt={img.fields.title}
              title={img.fields.title}
            />
          ))} */}
      </section>
    </Layout>
  );
}
