import { useEffect, useState } from "react";
import { getSinglePost } from "../utils/helpers";
import { TSinglePost } from "../utils/types";

export default function useSinglePost(slug: any) {
  const [post, setPost]: any = useState(null);
  const [isLoading, setLoading] = useState(true);
  const promise = getSinglePost(slug);
  useEffect(() => {
    promise.then((result) => {
      if (result?.items[0].fields) {
        setPost(result?.items[0].fields);
        setLoading(false);
      } else {
        setLoading(true);
      }
    });
    console.log("aloooooo");
  }, []);

  return [post, isLoading];
}
