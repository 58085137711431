import { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link, useNavigate } from "react-router-dom";
import {
  auth,
  registerWithEmailAndPassword,
  signInWithGoogle,
} from "../firebase";
import { ROUTES } from "../utils/constants";
import Layout from "../interfaces/Layout";

const Register = (): JSX.Element => {
  const { REGISTER, RESET, CHECKOUT, LOGIN } = ROUTES;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const history = useNavigate();
  const register = () => {
    if (!name) alert("Please enter name");
    registerWithEmailAndPassword(name, email, password);
  };

  useEffect(() => {
    if (loading) return;
    if (user) history(CHECKOUT);
  }, [user, loading]);

  return (
    <Layout background='staticBGDark'>
      <section className='container lg all-center-v flex-direction-column'>
        <h2>Register</h2>
        <article className='register__container'>
          <input
            type='text'
            className='register__textBox'
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder='Full Name'
          />
          <input
            type='text'
            className='register__textBox'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder='E-mail Address'
          />
          <input
            type='password'
            className='register__textBox'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder='Password'
          />
          <button className='register__btn' onClick={register}>
            Register
          </button>
          <button
            className='register__btn register__google'
            onClick={signInWithGoogle}
          >
            Register With Google
          </button>
          <div>
            Already have an account? <Link to={LOGIN}></Link>
          </div>
        </article>
      </section>
    </Layout>
  );
};

export default Register;
