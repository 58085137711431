import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailShareButton,
  TelegramShareButton,
} from "react-share";
import {
  FaFacebookF,
  FaLinkedinIn,
  FaTwitter,
  FaWhatsapp,
  FaTelegramPlane,
} from "react-icons/fa";
import { FiMail } from "react-icons/fi";

const Share = ({ socialConfig }: any) => {
  console.log("socialConfig: ", socialConfig);
  console.log("url: ", socialConfig.config.url);

  return (
    <section className='social-media'>
      <FacebookShareButton
        url={socialConfig.config.url}
        title={socialConfig.config.title}
        quote={socialConfig.config.title}
      >
        <span className='icon'>
          <FaFacebookF className='social-icons' size={25} />
        </span>
      </FacebookShareButton>
      <TwitterShareButton
        url={socialConfig.config.url}
        title={socialConfig.config.title}
        via={socialConfig.twitter.split("@").join("")}
      >
        <span className='icon'>
          <FaTwitter className='social-icons' size={25} />
        </span>
      </TwitterShareButton>
      <LinkedinShareButton
        url={socialConfig.config.url}
        title={socialConfig.config.title}
      >
        <span className='icon'>
          <FaLinkedinIn className='social-icons' size={25} />
        </span>
      </LinkedinShareButton>
      <WhatsappShareButton
        url={socialConfig.config.url}
        title={socialConfig.config.title}
      >
        <span className='icon'>
          <FaWhatsapp className='social-icons' size={25} />
        </span>
      </WhatsappShareButton>
      <TelegramShareButton
        url={socialConfig.config.url}
        title={socialConfig.config.title}
      >
        <span className='icon'>
          <FaTelegramPlane className='social-icons' size={25} />
        </span>
      </TelegramShareButton>
      <EmailShareButton
        url={socialConfig.config.url}
        title={socialConfig.config.title}
      >
        <span className='icon'>
          <FiMail className='social-icons' size={25} />
        </span>
      </EmailShareButton>
    </section>
  );
};

export default Share;
