import { useEffect, useState } from "react";
import { getContent } from "../utils/helpers";

export default function useContent(content_type: string, pageId?: string) {
  const [page, setPage] = useState<any>(null);
  const [total, setTotal] = useState<any>(null);
  const [isLoading, setLoading] = useState<any>(true);
  useEffect(() => {
    (async () => {
      await getContent(content_type, setPage, setLoading, setTotal, pageId);
    })();
  }, []);
  return [page, isLoading, total];
}
