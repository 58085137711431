import { client } from "../contentful/index";
import { PER_PAGE } from "./constants";

async function getContent(
  content_type: string,
  setContent: any,
  setLoading: any,
  setTotal: any,
  pageId?: string
) {
  try {
    const resp = await client.getEntries({
      content_type: content_type,
    });

    const filteredResults: any = resp.items.filter(
      (item: any) => item.fields.pageId === pageId
    );

    pageId ? setContent(filteredResults[0].fields) : setContent(resp.items);
    !pageId && setTotal(resp.total);
    setLoading(false);
  } catch (error) {
    console.log(error);
    setLoading(true);
  }
}

async function getMultipleContent(
  content_type: string,
  setContent: any,
  setLoading: any,
  setTotal: any,
  offset: number
) {
  try {
    const resp = await client.getEntries({
      content_type: content_type,
      skip: offset,
      limit: PER_PAGE,
    });
    // Page 1: skip=0, limit=15
    // Page 2: skip=15, limit=15
    // Page 3: skip=30, limit=15

    setContent(resp.items);
    setTotal(resp.total);
    setLoading(false);
  } catch (error) {
    console.log(error);
    setLoading(true);
  }
}

async function getSinglePost(slug: string) {
  try {
    const resp = await client.getEntries({
      "fields.slug": slug,
      content_type: "post",
    });
    return resp;

    // setContent(resp.items);
  } catch (error) {
    console.log(error);
  }
}

export { getContent, getMultipleContent, getSinglePost };
