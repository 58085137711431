import { useState } from "react";
import { PER_PAGE } from "../utils/constants";

export default function usePagination() {
  const [pagination, setPagination] = useState<any>({
    offset: 0,
    data: [],
    perPage: PER_PAGE,
    currentPage: 0,
  });

  const [offset, setOffset] = useState(0);
  const handlePageClick = (e: any) => {
    const selectedPage = e.selected;
    setOffset(selectedPage * pagination.perPage);
  };

  return [pagination, setPagination, handlePageClick, offset];
}
