import Layout from "../interfaces/Layout";
import { ROUTES } from "../utils/constants";
import { Link } from "react-router-dom";

const Error = (): JSX.Element => {
  const { HOME } = ROUTES;
  return (
    <Layout background='movingBG'>
      <section className='container lg all-center-v flex-direction-column'>
        <h1>Página não encontrada</h1>
        <p>
          Parece que essa página não existe,{" "}
          <Link to={HOME}>clique aqui para ir pra página principal</Link>.
        </p>
      </section>
    </Layout>
  );
};

export default Error;
