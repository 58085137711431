import { useEffect, useState } from "react";
import { getMultipleContent } from "../utils/helpers";

export default function useMultipleContent(
  content_type: string,
  offset: number
) {
  const [page, setPage] = useState<any>(null);
  const [total, setTotal] = useState<any>(null);
  const [isLoading, setLoading] = useState<any>(true);
  useEffect(() => {
    (async () => {
      await getMultipleContent(
        content_type,
        setPage,
        setLoading,
        setTotal,
        offset
      );
    })();
  }, [offset]);
  return [page, isLoading, total];
}
