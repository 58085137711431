import { FC } from "react";
import { TPosts } from "../utils/types";
import { Link, useParams } from "react-router-dom";

const Posts: FC<TPosts> = ({ posts }) => {
  return (
    <section className='blog-list'>
      {posts &&
        posts.map((post: any, index: number) => {
          const { title, images, content, slug, cover, intro } = post.fields;
          return (
            <section className='blog-post' key={index}>
              <div className='cover-img'>
                <img
                  src={cover.fields.file.url}
                  alt={cover.fields.title}
                  title={cover.fields.title}
                />
              </div>
              <h2>{title}</h2>
              <div>
                <p>{intro}</p>
                <Link key={slug} to={slug}>
                  Leia Mais
                </Link>
              </div>
            </section>
          );
        })}
    </section>
  );
};

export default Posts;
