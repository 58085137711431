import Posts from "../blog/Posts";
import Layout from "../interfaces/Layout";
import useMultipleContent from "../hooks/useMultipleContent";
import { PAGE_TYPES } from "../utils/constants";
import usePagination from "../hooks/usePagination";
import { useEffect } from "react";
import ReactPaginate from "react-paginate";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import Loading from "./Loading";
import { PER_PAGE } from "../utils/constants";

const Blog = (): JSX.Element => {
  const { POST } = PAGE_TYPES;
  const [pagination, setPagination, handlePageClick, offset] = usePagination();
  const [page, isLoading, total] = useMultipleContent(POST, offset);
  useEffect(() => {
    page &&
      setPagination((item: any) => ({
        ...item,
        pageCount: Math.ceil(total / item.perPage),
      }));
  }, [total, offset]);

  if (isLoading) return <Loading />;
  return (
    <Layout background='staticBGDark'>
      <section className='container md'>
        {page && <Posts posts={page} />}
      </section>
      {page.length !== 0 && pagination.pageCount > 1 && (
        <ReactPaginate
          previousLabel={<FaAngleLeft width='16px' height='16px' />}
          nextLabel={<FaAngleRight width='16px' height='16px' />}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={pagination.pageCount}
          marginPagesDisplayed={PER_PAGE}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          // subContainerClassName={"pages pagination"}
          activeClassName={"active"}
        />
      )}
    </Layout>
  );
};

export default Blog;
