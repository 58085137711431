import { DotsProps } from "./types";
import { clickPrev, clickNext, checkDotsProps } from "./helpers";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { v4 as uuid } from "uuid";

export const CarouselDots = (props: DotsProps): JSX.Element | null => {
  const { data, states, dotName, thumbsArrows, total, thumbsDirection, color } =
    props;
  const {
    selectedItem,
    activeIndex,
    setActiveIndex,
    autoSlide,
    setAutoSlide,
    autoSlideSeconds,
  } = states;

  const functionStates = {
    setActiveIndex,
    activeIndex,
  };

  const slideOptions = {
    autoSlide,
    setAutoSlide,
    autoSlideSeconds,
  };

  if (!data || data.length < 1) return null;

  return (
    <section
      className={`dots-parent thumbs ${
        thumbsDirection === "CENTER"
          ? "center"
          : thumbsDirection === "RIGHT"
          ? "right"
          : "left"
      }`}
      data-test='dots-container'
    >
      {thumbsArrows && (
        <button
          style={{ color: color }}
          className='prev-btn'
          data-test='thumbs-prev-btn'
          type='button'
          onClick={() => clickPrev(slideOptions, data, functionStates)}
          aria-label='previous'
        >
          <BsChevronLeft size={30} />
        </button>
      )}
      {data &&
        selectedItem &&
        data.map((item, index) => (
          <button
            data-test={checkDotsProps(dotName)}
            style={
              selectedItem.fields.file.url === item.fields.file.url
                ? { borderColor: color, backgroundColor: color }
                : { borderColor: color }
            }
            className={
              selectedItem === item
                ? `${checkDotsProps(dotName)} active`
                : checkDotsProps(dotName)
            }
            type='button'
            key={uuid()}
            aria-label={checkDotsProps(dotName)}
            onClick={() => setActiveIndex(index)}
          />
        ))}
      {thumbsArrows && (
        <button
          style={{ color: color }}
          className='next-btn'
          data-test='thumbs-next-btn'
          type='button'
          onClick={() => clickNext(slideOptions, data, functionStates)}
          aria-label='next'
        >
          <BsChevronRight size={30} />
        </button>
      )}
      {total && (
        <small data-test='active-item-total' style={{ color: color }}>
          {activeIndex + 1} of {data.length}
        </small>
      )}
    </section>
  );
};
