const ROUTES = {
  HOME: "/",
  ABOUT: "/sobre",
  BLOG: "/blog",
  BLOG_ID: "/blog/:id",
  CONTACT: "/contato",
  NOT_FOUND: "*",
  CREATE_PAYMENT: "/create-payment-intent",
  CHECKOUT: "/checkout",
  LOGIN: "/login",
  RESET: "/reset",
  REGISTER: "/register",
};

const BTNS = {
  COMPRAR: "compre agora",
};

const PAGE_TYPES = {
  PAGE: "page",
  POST: "post",
};

const PAGES = {
  HOME: "home",
  SOBRE: "sobre",
  CONTATO: "contato",
};

const KEYWORDS = ["despertar", "bruxa", "mudança", "vida", "itália"];

const TWITTER = "_fonsek";

const SITE_URL = "https://diana-molinari.web.app";

const MOBILE_WIDTH = 768;
const PER_PAGE = 5;

export {
  ROUTES,
  BTNS,
  PAGE_TYPES,
  KEYWORDS,
  TWITTER,
  SITE_URL,
  PAGES,
  MOBILE_WIDTH,
  PER_PAGE,
};
