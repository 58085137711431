import CarouselConfig from "./CarouselConfig";
import "./styles/main.sass";
import ActiveIndexProvider from "./context/ActiveIndexProvider";
import { CarouselProps } from "./CarouselConfig/types";

function Carousel(props: CarouselProps): JSX.Element | null {
  const {
    data,
    children,
    arrows,
    seconds,
    autoOnDesktop,
    autoOnMobile,
    thumbsArrows,
    total,
    thumbsDirection,
    color,
    dotName,
  } = props;
  return (
    <ActiveIndexProvider>
      <CarouselConfig
        data={data}
        arrows={arrows}
        autoOnDesktop={autoOnDesktop}
        autoOnMobile={autoOnMobile}
        seconds={seconds}
        thumbsArrows={thumbsArrows}
        thumbsDirection={thumbsDirection}
        total={total}
        color={color}
        dotName={dotName}
      >
        {children}
      </CarouselConfig>
    </ActiveIndexProvider>
  );
}

export default Carousel;
