import { SlideOptions, States, DotsSize } from "./types";

export const prevSlide = (data: Array<any>, states: States) => {
  const { setActiveIndex, activeIndex } = states;
  setActiveIndex(activeIndex !== 0 ? activeIndex - 1 : data.length - 1);
};

export const nextSlide = (data: Array<any>, states: States) => {
  const { setActiveIndex, activeIndex } = states;
  setActiveIndex(activeIndex !== data.length - 1 ? activeIndex + 1 : 0);
};

export const clickPrev = async (
  slideOptions: SlideOptions,
  data: Array<any>,
  states: States
) => {
  const { autoSlide, setAutoSlide, autoSlideSeconds } = slideOptions;
  const timeToSlide = autoSlideSeconds ? autoSlideSeconds * 1000 : 5000;
  autoSlide && setAutoSlide(false);
  prevSlide(data, states);
  autoSlide &&
    setTimeout(() => {
      setAutoSlide(true);
    }, timeToSlide);
};

export const clickNext = (
  slideOptions: SlideOptions,
  data: Array<any>,
  states: States
) => {
  const { autoSlide, setAutoSlide, autoSlideSeconds } = slideOptions;
  const timeToSlide = autoSlideSeconds ? autoSlideSeconds * 1000 : 5000;

  autoSlide && setAutoSlide(false);
  nextSlide(data, states);
  autoSlide &&
    setTimeout(() => {
      setAutoSlide(true);
    }, timeToSlide);
};

export const isNotMobileView = (width: number) => width && width > 768;

export const isAutoSlide = (
  width: number | undefined,
  autoOnDesktop: boolean,
  autoOnMobile: boolean
) => (width && isNotMobileView(width) ? autoOnDesktop : autoOnMobile);

export const checkBooleanProp = (prop: boolean | undefined) =>
  prop ? prop : false;

export const checkDotsProps = (dotName: DotsSize | undefined) =>
  dotName ? dotName : "SM-DOT";
