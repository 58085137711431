import Layout from "../interfaces/Layout";
import { ROUTES } from "../utils/constants";
import { Link } from "react-router-dom";

const Loading = (): JSX.Element => {
  const { HOME } = ROUTES;
  return (
    <Layout background='movingBG'>
      <section className='container lg all-center-v flex-direction-column'>
        <h1>Carregando...</h1>
        <p>
          Se essa página não abrir nos próximos segundos, infelizmente
          encontramos um erro,
          <Link to={HOME}>clique aqui para ir pra página principal</Link>
        </p>
      </section>
    </Layout>
  );
};

export default Loading;
