import { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { auth, sendPasswordReset } from "../firebase";
import Layout from "../interfaces/Layout";
import { ROUTES } from "../utils/constants";

const Reset = (): JSX.Element => {
  const { REGISTER, RESET, CHECKOUT, LOGIN } = ROUTES;
  const [email, setEmail] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (loading) return;
    if (user) navigate(CHECKOUT);
  }, [user, loading]);

  return (
    <Layout background='staticBGDark'>
      <section className='container lg all-center-v flex-direction-column'>
        <h1>Reset Password</h1>
        <article className='reset__container'>
          <input
            type='text'
            className='reset__textBox'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder='E-mail Address'
          />
          <button
            className='reset__btn'
            onClick={() => sendPasswordReset(email)}
          >
            Send password reset email
          </button>
          <div>
            Don't have an account? <Link to={REGISTER}>Register</Link> now.
          </div>
        </article>
      </section>
    </Layout>
  );
};

export default Reset;
