import { useState, useContext, FC } from "react";
import { Link } from "react-router-dom";
import { ROUTES, MOBILE_WIDTH } from "../utils/constants";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, logout } from "../firebase";
import { Context } from "../context/Context";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { FaBars, FaPlus } from "react-icons/fa";
import { LayoutProps } from "../utils/types";
import Menu from "../components/Menu";

const Layout: FC<LayoutProps> = ({ children, background }) => {
  const { HOME, LOGIN, REGISTER } = ROUTES;
  const [user, loading, error] = useAuthState(auth);
  const [menuMobile, setMenuMobile] = useState(false);
  const appContext = useContext(Context);
  const { width } = useWindowDimensions();

  const logo = <Link to={HOME}>DIANA MOLINARI</Link>;

  return (
    <section className={`wrapper ${background}`}>
      <header className='container lg sticky'>
        {width && width > MOBILE_WIDTH ? (
          <h3 className='logo z-index99'>{logo}</h3>
        ) : (
          <h6 className='logo z-index99'>{logo}</h6>
        )}
        {width && width > MOBILE_WIDTH ? (
          <Menu />
        ) : (
          <section>
            <p>
              <button
                className='btn-link z-index99 sticky'
                onClick={() =>
                  menuMobile ? setMenuMobile(false) : setMenuMobile(true)
                }
              >
                {!menuMobile ? (
                  <FaBars className='social-icons' size={25} />
                ) : (
                  <FaPlus className='social-icons close-icon' size={25} />
                )}
              </button>
            </p>
            <Menu
              className={`${
                menuMobile ? "active" : null
              } wrapper ${background}`}
            />
          </section>
        )}
      </header>
      <div className='main-content'>
        <section className='container all-right-h sticky login-text'>
          <p>
            {user ? (
              <small>
                Logged in as {appContext?.name} |&nbsp;
                <a onClick={logout} href=''>
                  Logout
                </a>
              </small>
            ) : (
              <small>
                Tem uma conta? Faça&nbsp;
                <Link to={LOGIN}>Login</Link> ou{" "}
                <Link to={REGISTER}>Registre-se</Link>
              </small>
            )}
          </p>
        </section>
        <section>{children}</section>
      </div>
      <footer>
        <div className='container lg'>
          <p>
            <small>
              Developed by&nbsp;
              <a
                href='http://gabrielfonseca.dev.br'
                target='_blank'
                rel='noreferrer'
              >
                Gabriel Fonseca
              </a>
            </small>
          </p>
        </div>
      </footer>
    </section>
  );
};

export default Layout;
